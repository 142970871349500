import { FormGroup } from '@angular/forms';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as moment from 'moment-timezone';

import { CoverDetails, Offer } from '../_services/types/offers.types';
import { Address } from '../ui-kit/components/google-autocomplete/google-autocomplete.component';

export const getComponentByType = (address: Address, type: string) => {
  if (!type) return null;
  if (!address?.address_components || address.address_components.length === 0) return null;
  type = type.toLowerCase();
  const res = address.address_components.find(
    (comp) =>
      comp.types &&
      comp.types.length > 0 &&
      comp.types.findIndex((x) => x.toLowerCase() === type) > -1,
  );
  return res;
};

export const formatPrice = (price: number) => {
  if (price == null || price === undefined) return 'CHF \\';

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'CHF',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat('de-CH', options).format(price);
};

export type FormGroupKeys<T extends FormGroup> = keyof T['controls'];

export const getBooleanLabel = (value: boolean): string =>
  value ? $localize`Oui` : $localize`Non`;

export const emailIsValid = (email: string): boolean =>
  !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const formatCoverDetails = (cover: CoverDetails) => {
  const details = [];
  if (cover.maximumReimbursed) {
    details.push($localize`Jusqu'à ${formatPrice(cover.maximumReimbursed)}`);
  }
  if (cover.deductible !== null && cover.deductible !== undefined) {
    details.push($localize`Franchise de ${formatPrice(cover.deductible)}`);
  }
  if (details) {
    return details.join(' - ');
  }
  return undefined;
};

export const removeDuplicateOffers = (offers: Offer[]) => {
  if (offers)
    for (let i = 0; i < offers.length - 1; i += 1) {
      for (let j = i + 1; j < offers.length; j += 1) {
        if (
          offers[i].insuranceName === offers[j].insuranceName &&
          offers[i].price === offers[j].price &&
          offers[i].covers?.every((x) =>
            offers[j].covers?.some(
              (y) =>
                x.title === y.title &&
                x.description === y.description &&
                x.details === y.details &&
                x.icon === y.icon &&
                x.importance === y.importance &&
                x.price === y.price,
            ),
          ) &&
          offers[j].covers?.every((x) =>
            offers[i].covers?.some(
              (y) =>
                x.title === y.title &&
                x.description === y.description &&
                x.details === y.details &&
                x.icon === y.icon &&
                x.importance === y.importance &&
                x.price === y.price,
            ),
          )
        )
          offers.splice(j, 1);
      }
    }
};

export const getAgeInYears = (value: Date): number =>
  Math.floor((new Date().getTime() - new Date(value).getTime()) / (1000 * 60 * 60 * 24 * 365.25));

export const scrollTo = (id: string) => {
  const element = document.getElementById(id);
  if (element) element.scrollIntoView();
};

/** Function that takes two strings (full names) and compares them semantically (eg: "first last" and "Last First" will be considered the same) */
export const sameStrings = (name1: string, name2: string): boolean => {
  const name1Array = name1
    .trim()
    .replace(/\s+/g, ' ')
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .split(' ');

  const name2Array = name2.trim().replace(/\s+/g, ' ').toLowerCase().replace(/\s+/, ' ').split(' ');

  return (
    name1Array.length === name2Array.length &&
    name1Array.every((x) => name2Array.includes(x)) &&
    name2Array.every((x) => name1Array.includes(x))
  );
};

export const dateFromString = (dateString: string): Date => {
  if (!dateString) return null;
  const momentDate = moment(dateString, 'DD/MM/YYYY');
  return momentDate.toDate();
};

export function waitForDomElement(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
