import {
  FunnelDatalayerEvent,
  SharedFunnelDatalayerEvent,
} from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import {
  getVehiclePrestationLabel,
  VehiclePrestationsEnum,
} from '../../enums/vehicle/vehicle-prestations.enum';
import { LinearStepperElement } from '../../ui-kit/components/linear-stepper/linear-stepper.component';
import { CarDrivingIconComponent } from '../../ui-kit/icons/car-driving-icon.component';
import { ForbiddenIconComponent } from '../../ui-kit/icons/forbidden-icon.component';
import { GarageIconComponent } from '../../ui-kit/icons/garage-icon.component';
import { HeadLightIconComponent } from '../../ui-kit/icons/headlight-icon.component';
import { ParkingIconComponent } from '../../ui-kit/icons/parking-icon.component';
import { RepairIconComponent } from '../../ui-kit/icons/repair-icon.component';
import { ShieldIconComponent } from '../../ui-kit/icons/shield-icon.component';
import { TireIconComponent } from '../../ui-kit/icons/tire-icon.component';
import { WalletIconComponent } from '../../ui-kit/icons/wallet-icon.component';

export const VEHICLE_BASE_URL = 'vehicule';

export const VEHICLE_FORM_BASE_URL = 'formulaire';

export const VEHICLE_OFFER_BASE_URL = 'offre';

export const VEHICLE_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Vehicule',
  product_category: 'Vehicule',
  product_name: 'Market',
  funnel_complete: false,
};

export const VEHICLE_STEPS_BASE = `${VEHICLE_BASE_URL}/${VEHICLE_FORM_BASE_URL}`;

export const VEHICLE_STEPS: {
  MAIN_FORM: Step;
  SECOND_FORM: Step;
  LEASING: Step;
} = {
  MAIN_FORM: {
    step: [1, 1],
    url: 'form_1',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Formulaire 1',
    },
  },
  SECOND_FORM: {
    step: [2, 1],
    url: 'form_2',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Formulaire 2',
    },
  },
  LEASING: {
    step: [3, 1],
    url: 'leasing',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Leasing',
    },
  },
};

export const VEHICLE_OFFER_BASE = `${VEHICLE_BASE_URL}/${VEHICLE_OFFER_BASE_URL}/`;

export const VEHICLE_OFFER_FORM_STEPS: {
  OFFERS: Step;
  OFFERS_OFFLINE: Step;
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  END: Step;
} = {
  OFFERS: {
    step: [6, 1],
    url: 'offers',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: 'Offres',
      funnel_complete: true,
    },
  },
  OFFERS_OFFLINE: {
    step: [6, 1],
    url: 'offers-offline',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: 'Offres',
      funnel_complete: true,
    },
  },
  ACCOUNT: {
    step: [1, 1],
    url: 'compte',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 14,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [1, 2],
    url: 'connexion',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [1, 2],
    url: 'inscription',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 15,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [2, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 16,
      stage_name: 'Informations personnelles',
    },
  },
  END: {
    step: [7, 1],
    url: 'fin',
    datalayerEvent: {
      ...VEHICLE_FUNNEL_EVENT_BASE,
      funnel_stage: 20,
      stage_name: 'Fin',
      funnel_complete: true,
    },
  },
};

export const VEHICLE_STEPS_NAMES: LinearStepperElement[] = [
  {
    title: $localize`Preneur d'assurance`,
  },
  {
    title: $localize`Informations`,
  },
  {
    title: $localize`Franchises`,
  },
  {
    title: $localize`Couvertures`,
  },
  {
    title: $localize`Questionnaire`,
  },
];

export const VEHICLE_INSURANCE_END_EVENT: FunnelDatalayerEvent = {
  ...VEHICLE_FUNNEL_EVENT_BASE,
  funnel_complete: true,
  funnel_stage: 21,
  stage_name: 'Fin',
};

export const VEHICLE_END_URL = `${VEHICLE_BASE_URL}/fin`;

export const COLLISION_DEDUCTIBLE = [
  { value: 500, label: 'CHF 500' },
  { value: 1000, label: "CHF 1'000" },
  { value: 2000, label: "CHF 2'000" },
];

export const PARTIAL_COVER_DEDUCTIBLE = [
  { value: 0, label: 'CHF 0' },
  { value: 100, label: 'CHF 100' },
  { value: 200, label: 'CHF 200' },
  { value: 300, label: 'CHF 300' },
  { value: 500, label: 'CHF 500' },
];

export const VEHICLE_COVERS: SelectElementMapper<VehiclePrestationsEnum> = {
  [VehiclePrestationsEnum.FREE_GARAGE_CHOICE]: {
    value: VehiclePrestationsEnum.FREE_GARAGE_CHOICE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.FREE_GARAGE_CHOICE),
    iconComponent: GarageIconComponent,
  },
  [VehiclePrestationsEnum.REPLACEMENT_VEHICLE]: {
    value: VehiclePrestationsEnum.REPLACEMENT_VEHICLE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.REPLACEMENT_VEHICLE),
    iconComponent: CarDrivingIconComponent,
  },
  [VehiclePrestationsEnum.PERSONAL_BELONGINGS]: {
    value: VehiclePrestationsEnum.PERSONAL_BELONGINGS,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PERSONAL_BELONGINGS),
    iconComponent: WalletIconComponent,
  },
  [VehiclePrestationsEnum.PASSENGERS_PROTECTION]: {
    value: VehiclePrestationsEnum.PASSENGERS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PASSENGERS_PROTECTION),
    iconComponent: ShieldIconComponent,
  },
  [VehiclePrestationsEnum.GROSS_NEGLIGENCE]: {
    value: VehiclePrestationsEnum.GROSS_NEGLIGENCE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GROSS_NEGLIGENCE),
    iconComponent: ForbiddenIconComponent,
  },
  [VehiclePrestationsEnum.TROUBESHOOTING]: {
    value: VehiclePrestationsEnum.TROUBESHOOTING,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TROUBESHOOTING),
    iconComponent: RepairIconComponent,
  },
  [VehiclePrestationsEnum.GLASS_PROTECTION]: {
    value: VehiclePrestationsEnum.GLASS_PROTECTION,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.GLASS_PROTECTION),
    iconComponent: HeadLightIconComponent,
  },
  [VehiclePrestationsEnum.TIRES]: {
    value: VehiclePrestationsEnum.TIRES,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.TIRES),
    iconComponent: TireIconComponent,
  },
  [VehiclePrestationsEnum.PARKING_DAMAGE]: {
    value: VehiclePrestationsEnum.PARKING_DAMAGE,
    label: getVehiclePrestationLabel(VehiclePrestationsEnum.PARKING_DAMAGE),
    iconComponent: ParkingIconComponent,
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 véhicule',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
  {
    name: 'Mandat gestion véhicule',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.MOTOR_VEHICLE,
  },
];
