import { SharedFunnelDatalayerEvent } from 'src/app/_services/types/datalayer.types';
import { Step } from 'src/app/_services/types/step.type';
import {
  getLegalProtectionCoverLabel,
  LegalProtectionCoversEnum,
} from 'src/app/enums/legal-protection/legal-protection-covers.enum';
import { LegalProtectionDetailsEnum } from 'src/app/enums/legal-protection/legal-protection-details.enum';
import { LegalProtectionOptionsEnum } from 'src/app/enums/legal-protection/legal-protection-options.enum';

import {
  BASE_ARTICLE_45,
  BASE_MANAGEMENT_AGREEMENT,
} from '../../_services/constants/base-contracts-info';
import { SelectElementMapper } from '../../_services/types/commons.type';
import { ContractToSignInfo } from '../../_services/types/contract-template.types';
import { ContractType } from '../../enums/contract-type.enum';
import { InsuranceTypeEnum } from '../../enums/insurance-type.enum';
import { LinearStepperElement } from '../../ui-kit/components/linear-stepper/linear-stepper.component';
import { MinimalistHouseIconComponent } from '../../ui-kit/icons/house-minimalist-icon.component';
import { KeyIconComponent } from '../../ui-kit/icons/key-icon.component';
import { RoadIconComponent } from '../../ui-kit/icons/road-icon.component';

export const LEGAL_PROTECTION_FUNNEL_EVENT_BASE: SharedFunnelDatalayerEvent = {
  event: 'Market_Protection Juridique',
  product_category: 'Protection Juridique',
  product_name: 'Market',
  funnel_complete: false,
};

export const LEGAL_PROTECTION_BASE_URL = 'protection-juridique';

export const LEGAL_PROTECTION_FORM_BASE_URL = 'formulaire';

export const LEGAL_PROTECTION_OFFER_BASE_URL = 'offre';

export const LEGAL_PROTECTION_STEPS_BASE = `${LEGAL_PROTECTION_BASE_URL}/${LEGAL_PROTECTION_FORM_BASE_URL}/`;
export const LEGAL_PROTECTION_OFFER_FORM_STEPS_BASE = `${LEGAL_PROTECTION_BASE_URL}/${LEGAL_PROTECTION_OFFER_BASE_URL}/`;

export const LEGAL_PROTECTION_STEPS: {
  POLICYHOLDER: Step;
  COVERS: Step;
  OFFRES: Step;
  ACCOUNT: Step;
  LOGIN: Step;
  REGISTER: Step;
  PERSONAL_INFO: Step;
  CONTRACTUAL_QUESTIONARY: Step;
  SUMMARY: Step;
  CO_INSURED: Step;
  CONTRACT_SIGNATURE: Step;
  INSURANCE_CONTRACT_SIGNATURE: Step;
  CANCELLATION: Step;
  END: Step;
} = {
  POLICYHOLDER: {
    step: [1, 1],
    url: 'preneur-assurance',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 1,
      stage_name: 'Preneur assurance',
    },
  },
  COVERS: {
    step: [2, 1],
    url: 'couvertures',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 2,
      stage_name: 'Couvertures',
    },
  },
  OFFRES: {
    step: [3, 1],
    url: 'offres',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 3,
      stage_name: 'Offres',
    },
  },
  ACCOUNT: {
    step: [3, 1],
    url: 'compte',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 4,
      stage_name: 'Compte',
    },
  },
  LOGIN: {
    step: [3, 1],
    url: 'connexion',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Connexion',
    },
  },
  REGISTER: {
    step: [3, 1],
    url: 'inscription',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 5,
      stage_name: 'Inscription',
    },
  },
  PERSONAL_INFO: {
    step: [3, 1],
    url: 'informations-personnelles',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 6,
      stage_name: 'Informations personnelles',
    },
  },
  CONTRACTUAL_QUESTIONARY: {
    step: [4, 1],
    url: 'questionnaire-contractuel',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 7,
      stage_name: 'Questionnaire contractuel',
    },
  },
  SUMMARY: {
    step: [5, 1],
    url: 'recapitulatif',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 9,
      stage_name: 'Récapitulatif',
    },
  },
  CO_INSURED: {
    step: [6, 1],
    url: 'co-assures',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 8,
      stage_name: 'Co-assurés',
    },
  },
  CONTRACT_SIGNATURE: {
    step: [6, 1],
    url: 'signature-contrat',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 10,
      stage_name: 'Signatures',
    },
  },
  INSURANCE_CONTRACT_SIGNATURE: {
    step: [6, 1],
    url: 'signature-contrat-assurance',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 11,
      stage_name: 'Signature contrat assurance',
    },
  },
  CANCELLATION: {
    step: [7, 1],
    url: 'resiliation',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 12,
      stage_name: '(Option) Résiliation',
    },
  },
  END: {
    step: [8, 1],
    url: 'fin',
    datalayerEvent: {
      ...LEGAL_PROTECTION_FUNNEL_EVENT_BASE,
      funnel_stage: 13,
      stage_name: 'Fin',
      funnel_complete: true,
    },
  },
};

export const LEGAL_PROTECTION_END_URL = `${LEGAL_PROTECTION_BASE_URL}/fin`;

export const LEGAL_PROTECTION_COVERS: SelectElementMapper<LegalProtectionCoversEnum> = {
  [LegalProtectionCoversEnum.PRIVATE]: {
    value: LegalProtectionCoversEnum.PRIVATE,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.PRIVATE),
    iconComponent: KeyIconComponent,
  },
  [LegalProtectionCoversEnum.CIRCULATION]: {
    value: LegalProtectionCoversEnum.CIRCULATION,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.CIRCULATION),
    iconComponent: RoadIconComponent,
  },
  [LegalProtectionCoversEnum.BUILDING]: {
    value: LegalProtectionCoversEnum.BUILDING,
    label: getLegalProtectionCoverLabel(LegalProtectionCoversEnum.BUILDING),
    iconComponent: MinimalistHouseIconComponent,
    require: LegalProtectionCoversEnum.PRIVATE,
  },
};

export const LEGAL_PROTECTION_OPTIONS: SelectElementMapper<LegalProtectionOptionsEnum> = {
  [LegalProtectionOptionsEnum.MULTI_RISK]: {
    value: LegalProtectionOptionsEnum.MULTI_RISK,
    label: $localize`Multi Risk`,
  },
  [LegalProtectionOptionsEnum.INDEPENDANT]: {
    value: LegalProtectionOptionsEnum.INDEPENDANT,
    label: $localize`Indépendant`,
  },
  [LegalProtectionOptionsEnum.BUILDING]: {
    value: LegalProtectionOptionsEnum.BUILDING,
    label: $localize`Bâtiment`,
  },
  [LegalProtectionOptionsEnum.LANDLORD]: {
    value: LegalProtectionOptionsEnum.LANDLORD,
    label: $localize`Bailleur`,
  },
};

export const LEGAL_PROTECTION_DETAILS: SelectElementMapper<LegalProtectionDetailsEnum> = {
  [LegalProtectionDetailsEnum.CONSUMER_LAW]: {
    value: LegalProtectionDetailsEnum.CONSUMER_LAW,
    label: $localize`Droit des consommateurs et autre droit des contrats`,
  },
  [LegalProtectionDetailsEnum.PROPERTY_LAW]: {
    value: LegalProtectionDetailsEnum.PROPERTY_LAW,
    label: $localize`Droit de la propriété et droits réels`,
  },
  [LegalProtectionDetailsEnum.LABOR_LAW]: {
    value: LegalProtectionDetailsEnum.LABOR_LAW,
    label: $localize`Droit du travail`,
  },
  [LegalProtectionDetailsEnum.GENDER_EQUALITY]: {
    value: LegalProtectionDetailsEnum.GENDER_EQUALITY,
    label: $localize`Droit de l’égalité entre femmes et hommes`,
  },
  [LegalProtectionDetailsEnum.LANDLORD_LITIGATION]: {
    value: LegalProtectionDetailsEnum.LANDLORD_LITIGATION,
    label: $localize`Droit du bail`,
  },
  [LegalProtectionDetailsEnum.ASSOCIATION_LAW]: {
    value: LegalProtectionDetailsEnum.ASSOCIATION_LAW,
    label: $localize`Droit associatif`,
  },
  [LegalProtectionDetailsEnum.INSURANCE_LAW]: {
    value: LegalProtectionDetailsEnum.INSURANCE_LAW,
    label: $localize`Droit des assurances`,
  },
  [LegalProtectionDetailsEnum.PATIENT_LAW]: {
    value: LegalProtectionDetailsEnum.PATIENT_LAW,
    label: $localize`Droit des patients`,
  },
  [LegalProtectionDetailsEnum.CIVIL_RESPONSIBILITY_LAW]: {
    value: LegalProtectionDetailsEnum.CIVIL_RESPONSIBILITY_LAW,
    label: $localize`Droit de la responsabilité civile extracontractuelle`,
  },
  [LegalProtectionDetailsEnum.VICTIM_ASSISTANCE]: {
    value: LegalProtectionDetailsEnum.VICTIM_ASSISTANCE,
    label: $localize`Aide aux victimes`,
  },
  [LegalProtectionDetailsEnum.MATRIMONIAL_LAW]: {
    value: LegalProtectionDetailsEnum.MATRIMONIAL_LAW,
    label: $localize`Droit du mariage, du divorce et du partenariat enregistré`,
  },
  [LegalProtectionDetailsEnum.INHERITANCE_LAW]: {
    value: LegalProtectionDetailsEnum.INHERITANCE_LAW,
    label: $localize`Droit successoral`,
  },
  [LegalProtectionDetailsEnum.LAND_LAW]: {
    value: LegalProtectionDetailsEnum.LAND_LAW,
    label: $localize`Droit de la propriété foncière`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_LAW]: {
    value: LegalProtectionDetailsEnum.VEHICLE_LAW,
    label: $localize`Droit des contrats pour les véhicules`,
  },
  [LegalProtectionDetailsEnum.CRIMINAL_LAW]: {
    value: LegalProtectionDetailsEnum.CRIMINAL_LAW,
    label: $localize`Droit pénal et sanctions de droit administratif`,
  },
  [LegalProtectionDetailsEnum.TAX_LAW]: {
    value: LegalProtectionDetailsEnum.TAX_LAW,
    label: $localize`Droit fiscal`,
  },
  [LegalProtectionDetailsEnum.CYBER_RISK]: {
    value: LegalProtectionDetailsEnum.CYBER_RISK,
    label: $localize`Cyber Risk`,
  },
  [LegalProtectionDetailsEnum.SCHOOL_LAW]: {
    value: LegalProtectionDetailsEnum.SCHOOL_LAW,
    label: $localize`Droit scolaire`,
  },
  [LegalProtectionDetailsEnum.CHILDREN_LAW]: {
    value: LegalProtectionDetailsEnum.CHILDREN_LAW,
    label: $localize`Droit de la protection de l'enfant et de l'adulte`,
  },
  [LegalProtectionDetailsEnum.DEATH_INSURANCE]: {
    value: LegalProtectionDetailsEnum.DEATH_INSURANCE,
    label: $localize`Protection juridique en cas de décès`,
  },
  [LegalProtectionDetailsEnum.ANIMAL_LAW]: {
    value: LegalProtectionDetailsEnum.ANIMAL_LAW,
    label: $localize`Droit du propriétaire d'animaux`,
  },
  [LegalProtectionDetailsEnum.DEPTH_INSURANCE]: {
    value: LegalProtectionDetailsEnum.DEPTH_INSURANCE,
    label: $localize`Recouvrement de créances`,
  },
  [LegalProtectionDetailsEnum.CONSTRUCTION_CONTRACT]: {
    value: LegalProtectionDetailsEnum.CONSTRUCTION_CONTRACT,
    label: $localize`Droit des contrats de la construction`,
  },
  [LegalProtectionDetailsEnum.POPERTY_LAW]: {
    value: LegalProtectionDetailsEnum.POPERTY_LAW,
    label: $localize`Droit immobilier`,
  },
  [LegalProtectionDetailsEnum.DATA_PROTECTION]: {
    value: LegalProtectionDetailsEnum.DATA_PROTECTION,
    label: $localize`Droit de la protection des données`,
  },
  [LegalProtectionDetailsEnum.INTELECTUAL_PROPERTY]: {
    value: LegalProtectionDetailsEnum.INTELECTUAL_PROPERTY,
    label: $localize`Droit de la propriété intellectuelle`,
  },
  [LegalProtectionDetailsEnum.INDEPENDANT_ACTIVITY]: {
    value: LegalProtectionDetailsEnum.INDEPENDANT_ACTIVITY,
    label: $localize`Activité accessoire indépendante`,
  },
  [LegalProtectionDetailsEnum.WEB_LAW]: {
    value: LegalProtectionDetailsEnum.WEB_LAW,
    label: $localize`Droit de l’internet`,
  },
  [LegalProtectionDetailsEnum.NATIONALITY_LAW]: {
    value: LegalProtectionDetailsEnum.NATIONALITY_LAW,
    label: $localize`Droit de la nationalité`,
  },
  [LegalProtectionDetailsEnum.PERSONALITY_LAW]: {
    value: LegalProtectionDetailsEnum.PERSONALITY_LAW,
    label: $localize`Droit de la personnalité`,
  },
  [LegalProtectionDetailsEnum.NEIGHBORHOOD_LAW]: {
    value: LegalProtectionDetailsEnum.NEIGHBORHOOD_LAW,
    label: $localize`Droit de voisinage`,
  },
  [LegalProtectionDetailsEnum.CONTRACTS_LAW]: {
    value: LegalProtectionDetailsEnum.CONTRACTS_LAW,
    label: $localize`Droit des contrats`,
  },
  [LegalProtectionDetailsEnum.CUSTOMS_LAW]: {
    value: LegalProtectionDetailsEnum.CUSTOMS_LAW,
    label: $localize`Droit douanier`,
  },
  [LegalProtectionDetailsEnum.LANDLORD_LAW]: {
    value: LegalProtectionDetailsEnum.LANDLORD_LAW,
    label: $localize`Droit de bail`,
  },
  [LegalProtectionDetailsEnum.TRAVEL_LAW]: {
    value: LegalProtectionDetailsEnum.TRAVEL_LAW,
    label: $localize`Voyage à l'étranger`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_TAX]: {
    value: LegalProtectionDetailsEnum.VEHICLE_TAX,
    label: $localize`Imposition des véhicules`,
  },
  [LegalProtectionDetailsEnum.LICENCE_DRIVE_WITHDRAWAL]: {
    value: LegalProtectionDetailsEnum.LICENCE_DRIVE_WITHDRAWAL,
    label: $localize`Retrait du permis de conduire`,
  },
  [LegalProtectionDetailsEnum.WORK_CONTRACT]: {
    value: LegalProtectionDetailsEnum.WORK_CONTRACT,
    label: $localize`Droit public de la construction`,
  },
  [LegalProtectionDetailsEnum.EXPROPRIATION_RIGHT]: {
    value: LegalProtectionDetailsEnum.EXPROPRIATION_RIGHT,
    label: $localize`Droit de l’expropriation`,
  },
  [LegalProtectionDetailsEnum.FLOOR_PROPERTY_RIGHT]: {
    value: LegalProtectionDetailsEnum.FLOOR_PROPERTY_RIGHT,
    label: $localize`Droit de la propriété par étages`,
  },
  [LegalProtectionDetailsEnum.PUBLIC_CONSTRUCTION_RIGHT]: {
    value: LegalProtectionDetailsEnum.PUBLIC_CONSTRUCTION_RIGHT,
    label: $localize`Droit public de la construction`,
  },
  [LegalProtectionDetailsEnum.MANDATE]: {
    value: LegalProtectionDetailsEnum.MANDATE,
    label: $localize`Mandat`,
  },
  [LegalProtectionDetailsEnum.SERVITUDE]: {
    value: LegalProtectionDetailsEnum.SERVITUDE,
    label: $localize`Servitudes`,
  },
  [LegalProtectionDetailsEnum.DAMAGES]: {
    value: LegalProtectionDetailsEnum.DAMAGES,
    label: $localize`Dommages-intérêts`,
  },
  [LegalProtectionDetailsEnum.RIGHT_PROTECTION]: {
    value: LegalProtectionDetailsEnum.RIGHT_PROTECTION,
    label: $localize`Droits réels`,
  },
  [LegalProtectionDetailsEnum.COPYRIGHT]: {
    value: LegalProtectionDetailsEnum.COPYRIGHT,
    label: $localize`Droit d’auteur`,
  },
  [LegalProtectionDetailsEnum.HARASSMENT]: {
    value: LegalProtectionDetailsEnum.HARASSMENT,
    label: $localize`Contre harclèment`,
  },
  [LegalProtectionDetailsEnum.FOREIGN_TRAVEL]: {
    value: LegalProtectionDetailsEnum.FOREIGN_TRAVEL,
    label: $localize`Voyage à l'étranger`,
  },
  [LegalProtectionDetailsEnum.VEHICLE_CONTRACT]: {
    value: LegalProtectionDetailsEnum.VEHICLE_CONTRACT,
    label: $localize`Contrats en rapport avec un véhicule`,
  },
  [LegalProtectionDetailsEnum.GARAGE_RENT]: {
    value: LegalProtectionDetailsEnum.GARAGE_RENT,
    label: $localize`Location d’un garage`,
  },
};

export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    ...BASE_ARTICLE_45,
    name: 'Article 45 protection juridique',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.LEGAL_PROTECTION,
  },
  {
    ...BASE_MANAGEMENT_AGREEMENT,
    name: 'Mandat gestion protection juridique',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.LEGAL_PROTECTION,
  },
];

export const END_SCREEN_TEXTS: LinearStepperElement[] = [
  {
    title: $localize`Ta police est en attente de validation (3 à 10 jours) `,
  },
  {
    title: $localize`Ta police est créée et accessible dans ton Wallet `,
  },
  {
    title: $localize`Si tu nous a confié la résiliation de ta/tes polices actuelle(s), nous envoyons le(s) courrier(s) de résiliation précedemment validé(s)`,
  },
];
